export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"65325635dbe26f0020fd31ee",
				"65325635dbe26f0020fd31f1"
			]
		},
		"65325635dbe26f0020fd31ee": {
			"id": "65325635dbe26f0020fd31ee",
			"name": "404",
			"pageUrl": "404"
		},
		"65325635dbe26f0020fd31f1": {
			"id": "65325635dbe26f0020fd31f1",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"background": "#ff0000 repeating-linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%) 0% 0% /auto repeat scroll padding-box"
		},
		"seo": {}
	}
}