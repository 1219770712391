import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Span, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Quarkly export</title>
        <meta name={"description"} content={"PEPE BULL SAVES"} />
        <link rel={"shortcut icon"} href={"#"} type={"image/x-icon"} />
      </Helmet>
      <Box
        min-width="100px"
        min-height="100px"
        display="flex"
        align-items="center"
        justify-content="flex-start"
      >
        <Text
          margin="0px 0px 0px 0px"
          display="block"
          padding="0px 0px 0px 80px"
          font="normal 900 72px/1.2 --fontFamily-sans"
          width="1280ps"
          align-self="center"
          flex="1 1 0%"
          position="relative"
          color="#00a947"
        >
          PEPE BULL{" "}
        </Text>
        <Text
          margin="0px 0px 0px 0px"
          font="25px/120% --fontFamily-googleMaterialSymbolsOutlined"
          border-color="#da0000"
          color="#ff00cf"
          width="50% content-box"
          flex="0 1 auto"
          order="1"
          align-self="auto"
        >
          Coming <br />
          10/21/23
        </Text>
      </Box>
      <Box min-width="100px" min-height="100px">
        <Text
          margin="0px 0px 0px 0px"
          padding="0px 0px 0px 80px"
          color="#f7b700"
          font="16px --fontFamily-googleMaterialSymbolsOutlined"
        >
          The thing nobody sees but is already here.
          <br />
          <br />
          CA: TBD <br />
          Telegram:{" "}
          <Span
            overflow-wrap="normal"
            word-break="normal"
            white-space="normal"
            text-indent="0"
            text-overflow="clip"
            hyphens="manual"
            user-select="auto"
            pointer-events="auto"
          >
            <Link
              href="https://t.me/pepebullerc"
              overflow-wrap="normal"
              word-break="normal"
              white-space="normal"
              text-indent="0"
              text-overflow="clip"
              hyphens="manual"
              user-select="auto"
              pointer-events="auto"
            >
              https://t.me/pepebullerc
            </Link>
          </Span>
        </Text>
      </Box>
      <Section padding="0px 0 0px 0" quarkly-title="Advantages/Features-22">
        <Override
          slot="SectionContent"
          max-width="none"
          width="100%"
          display="flex"
          flex-direction="row"
          flex-wrap="wrap"
          sm-flex-direction="column"
        />
        <Box
          width="33.33%"
          background="--color-orange"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="column"
          padding="48px 48px 48px 48px"
          sm-width="100%"
        >
          <Image
            src="https://uploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11:14:30.781Z"
            display="block"
            width="80px"
            srcSet="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=3200 3200w"
            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
          />
          <Text
            margin="10px 0px 2px 0px"
            font="normal 300 16px/1.5 --fontFamily-googleCagliostro"
            text-transform="uppercase"
            color="--light"
            text-align="center"
            padding="px 0px 0px 0px"
          >
            1/1 TAx
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="normal 300 16px/1.5 --fontFamily-googleCagliostro"
            color="--light"
            text-align="center"
          >
            ADDERRAL BOUGHT FOR DEV CAN COOK
          </Text>
        </Box>
        <Box
          width="33.33%"
          background="--color-orange radial-gradient(at center,#ef0707 0%,#000000 100%,#0f5500 100%)"
          display="flex"
          align-items="center"
          justify-content="center"
          flex-direction="column"
          padding="48px 48px 48px 48px"
          sm-width="100%"
        >
          <Image
            src="https://uploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11:14:30.781Z"
            display="block"
            width="80px"
            transition="opacity 0.5s ease-in 1s"
            srcSet="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/pixcleanerResult.png?v=2023-10-20T11%3A14%3A30.781Z&quality=85&w=3200 3200w"
            sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
          />
          <Text
            margin="10px 0px 2px 0px"
            font="1em --fontFamily-googleCagliostro"
            text-transform="uppercase"
            color="--light"
            text-align="center"
          >
            RETARDED TALKS IN TELEGRAM
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            font="1em --fontFamily-googleCagliostro"
            color="--light"
            text-align="center"
          >
            MEET PEPE. BULL EVERYONE
          </Text>
        </Box>
        <Image
          src="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/3dgifmaker94576.gif?v=2023-10-20T11:34:43.015Z"
          display="block"
          overflow-x="scroll"
          overflow-y="scroll"
          position="fixed"
          bottom="auto"
          height="353.2161407470703px"
          left="auto"
          right="-83px"
          top="93px"
          width="580.1041870117188px"
          lg-bottom="auto"
          lg-left="auto"
          lg-right="-134px"
          lg-top="94px"
        />
      </Section>
      <Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          flex="0 1 auto"
          display="flex"
          justify-content="center"
          align-items="center"
        />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 20px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 30px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--green"
            font="normal 900 72px/1.2 --fontFamily-googleMonofett"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            padding="0px 16px 0px 0px"
          >
            Our mission is to survive until bull pepe saves us.
          </Text>
        </Box>
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="flex-start"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="28px 0px 16px 16px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px -60px 0px"
            color="--primary"
            font="normal 300 20px/1.5 --fontFamily-googleCookie"
            lg-text-align="center"
            display="flex"
            align-items="flex-start"
            padding="0px 0px 0 0px"
          >
            WE CAN DO IT. AND WE WILL. SURVIVWE AND COME BACK STRONGER. AND RICH, OF COURSE.{" "}
          </Text>
          <Image
            src="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/3dgifmaker64442.gif?v=2023-10-20T12:49:50.795Z"
            display="block"
            object-fit="cover"
            margin="0px 0px 0px 5px"
            width="120%"
            sm-width="100%"
            sm-margin="0 0 10px 0"
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          margin="80px 0px 0px 0px"
          lg-margin="40px 0px 0px 0px"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="32px"
          sm-grid-template-columns="1fr"
          align-self="flex-end"
          flex="1 1 0%"
          order="0"
          align-items="center"
          justify-content="center"
        >
          <Image
            src="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/3dgifmaker85443.gif?v=2023-10-20T12:07:34.472Z"
            object-fit="cover"
            width="60%"
            height="100%"
            grid-column="2 / span 2"
            border-radius="24px"
            sm-grid-column="1 / span 1"
          />
        </Box>
      </Section>
      <Section
        padding="80px 0 90px 0"
        lg-padding="80px 0 80px 0"
        background="--color-lightD1 linear-gradient(0deg,#d61111 0%,#00ff00 100%)"
        quarkly-title="Content-15"
      >
        <Override slot="SectionContent" flex-direction="row" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
          flex-direction="column"
          width="600px"
          margin="0px 20px 0px 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            lg-width="100%"
            display="flex"
            flex-direction="row"
            justify-content="center"
            lg-margin="0px 0px 50px 0px"
            margin="0px 0px 35px 0px"
            sm-flex-direction="column"
            width="auto"
          >
            <Image
              src="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/3dgifmaker05914.gif?v=2023-10-20T11:43:07.496Z"
              display="block"
              object-fit="cover"
              margin="0px 0px 0px 5px"
              width="90%"
              sm-width="100%"
              sm-margin="0 0 10px 0"
            />
            <Image
              src="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/3dgifmaker05914.gif?v=2023-10-20T11:43:07.496Z"
              display="block"
              object-fit="cover"
              margin="0px 0px 0px 5px"
              width="90%"
              sm-width="100%"
              sm-margin="0 0 10px 0"
            />
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="center"
            lg-padding="0px 0px 0px 0"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 25px 0px"
              font="normal 300 25px/1.5 --fontFamily-googleMaterialSymbolsOutlined"
              color="#474e53"
              text-align="left"
              max-width="640px"
            >
              Imagine not being bull Right now..
              <br />
              Can't be me.{" "}
            </Text>
          </Box>
        </Box>
        <Image
          src="https://smartuploads.quarkly.io/65325635dbe26f0020fd31e9/images/3dgifmaker92198.gif?v=2023-10-20T11:41:11.251Z"
          object-fit="cover"
          width="100%"
          height="100%"
          grid-column="1 / span 1"
          border-radius="24px"
          position="static"
        />
        <Box
          display="flex"
          width="50%"
          flex-direction="column"
          justify-content="center"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 20px 0px"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 0px"
          lg-width="100%"
          md-margin="0px 0px 20px 0px"
          sm-margin="0px 0px 30px 0px"
          margin="0px 0px 0px 20px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--green"
            font="--headline1"
            lg-text-align="center"
            sm-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            padding="0px 16px 0px 0px"
            text-align="center"
          >
            OR JESUS. Anyway we bull like saylor
          </Text>
        </Box>
      </Section>
      <Section background="--color-darkL1" padding="50px 0 50px 0" quarkly-title="Footer-19">
        <Text
          margin="0px 0px 0px 0px"
          color="#ffffff"
          order="1"
          text-align="right"
          font="16px --fontFamily-googleCagliostro"
        >
          (C) PEPE BULL 2035
        </Text>
        <Box
          display="flex"
          justify-content="space-between"
          border-color="#232a44"
          md-flex-direction="column"
          font="16px --fontFamily-googleCagliostro"
        >
          <Box
            display="flex"
            md-margin="0px 0px 15px 0px"
            sm-flex-direction="column"
            justify-content="flex-start"
            align-items="center"
            grid-gap="24px"
          >
            <Box display="flex" grid-gap="8px">
              <Text
                margin="0px 0px 0px 0px"
                color="white"
                font="normal 300 16px/1.5 --fontFamily-googleCagliostro"
              >
                CA: TBD{" "}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>

      <RawHtml>
        <style place={"endOfHead"} rawKey={"65325635dbe26f0020fd31e7"}>
          {":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
        </style>
      </RawHtml>
    </Theme>
  );
};
